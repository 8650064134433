:root {
  --color-bg: white;
  --color-text: black;
  --color-text-light: var(--color-bg);
  --min-height-header: 50px;
  --max-height-header: 100px;

  background-color: var(--color-bg);
  color: var(--color-text);
  font-family: Ampersand, Brackets, Larken;
  // font-family: Larken;
  font-size: 18px;
}

// .curly-quote {
//   font-family: Georgia, "Times New Roman", Times, serif;
//   // font-size: 2rem;
// }

.external-url {
  position: relative;
  &:hover:before {
    position: absolute;

    content: " ";
    background-image: url("icons/logo-arrowright.svg");
    background-size: contain;
    width: 0.5em;
    height: 0.5em;
    left: -0.66em;
    top: 0.43em;

    // content: "⮌";
    // right: -1em;
  }
}

h1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

h2 {
  margin-bottom: 15px;
}

p {
  margin: 0;
  line-height: 1.3em;
}

#App {
  color: var(--color-text);
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  --scrollbar-width: 18px;
  &.touchDevice {
    --scrollbar-width: 0px;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

*:focus {
  outline: black auto 2px;
  outline-offset: 4px;

  // border: 1px solid black;
  // margin: -1px;
}

nav {
  a {
    text-decoration: none;
    // font-style: italic;
    // font-weight: bold;

    border-bottom: 2px solid transparent;
    &:not(.external-url):hover {
      border-color: var(--color-text);
    }
    &.active {
      border-color: var(--color-text);
    }
  }
}

#Header {
  margin-right: calc(var(--scrollbar-width));

  padding: 2vmin 0 0 0;
  // padding: 2vmin 0;

  transition: padding 0.5s;

  // background-color: var(--color-text);
  // color: var(--color-bg);

  nav {
    height: calc(clamp(var(--min-height-header), var(--max-height-header), 8vw));
    // margin-bottom: 15px;

    display: flex;

    grid-template-columns: repeat(7, 1fr);
    align-items: center;
    justify-items: center;

    position: relative;

    .space {
      flex: 1;
      display: flex;
      justify-content: space-around;
      height: 100%;
    }

    .header-item-container {
      font-size: calc(clamp(0.66rem, 1.5rem, 3vw));
      display: flex;
      align-items: center;

      height: 100%;
      position: relative;
      .tag-toggle {
        cursor: pointer;
        position: absolute;
        left: calc(50% - 0.5em);
        // bottom: -115%;
        bottom: 0; //calc(-0.5 * clamp(var(--min-height-header), var(--max-height-header), 8vw));
        font-size: calc(max(0.5rem, 0.5em));
        width: 1em;

        transition: transform 0.25s;
        &:hover {
          font-weight: bold;
        }
        &.active {
          transform: rotate(180deg);
        }
      }
    }

    .image-mock {
      width: 10vw;
    }
    .logo {
      // position: absolute;
      // height: 100%;
      // left: 50%;
      // top: 0;
      // transform: translateX(-50%);
      height: 100%;
    }
  }

  .tags-space {
    height: 2vmin;
    width: 100%;
    transition: height 0.5s;
    position: relative;

    #Tags {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.active {
      height: 60px;
    }
  }
}

#Tags {
  transition: opacity 0.5s;
  // position: relative;
  width: 100%;
  display: flex;
  height: 60px;

  pointer-events: none;
  &.active {
    pointer-events: all;
    transition-delay: 0.25s;
  }

  opacity: 1;
  visibility: visible;
  &:not(.active) {
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
    visibility: hidden;
    // height: 0px;
  }

  .left-arrow,
  .right-arrow {
    cursor: pointer;
    visibility: hidden;
    // transition: opacity 0.1s;
    position: absolute;
    bottom: 0;

    // object-fit: contain;
    height: 100%;
    width: 12px;
    padding: 0 10px;
    z-index: 1;

    transition: transform 0.1s;
    &:active {
      transform: scale(0.75);
    }
  }
  .left-arrow {
    left: 0;
    padding-right: 25px;
  }
  .right-arrow {
    right: 0;
    padding-left: 25px;
  }

  .tags-body {
    width: 100%;
    display: flex;
  }

  $fadeStart: 40px;
  $fadeEnd: 30px;

  &.scrollLeft {
    .tags-body {
      mask-image: linear-gradient(to left, black calc(100% - #{$fadeStart}), transparent calc(100% - #{$fadeEnd}));
    }
    .left-arrow {
      visibility: visible;
    }
  }
  &.scrollRight {
    .tags-body {
      mask-image: linear-gradient(to right, black calc(100% - #{$fadeStart}), transparent calc(100% - #{$fadeEnd}));
    }
    .right-arrow {
      visibility: visible;
    }
  }

  &.scrollLeft.scrollRight {
    .tags-body {
      mask-image: linear-gradient(
        to right,
        transparent #{$fadeEnd},
        black #{$fadeStart},
        black calc(100% - #{$fadeStart}),
        transparent calc(100% - #{$fadeEnd})
      );
    }
  }

  .scroll-horizontal {
    & > div {
      align-items: center;
      gap: 10px;
      padding: 0 25px;
    }
  }

  .tags-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    width: 100%;
    overflow: auto;
    position: relative;
  }

  .tag {
    line-height: 1.1em;
    white-space: nowrap;
    // border: 2px solid var(--color-text);
    border-top: none;
    border-bottom: none;
    // border-left: none;
    // border-right: none;
    // border-radius: 30px;
    text-decoration: none;
    padding: 5px;

    &.active {
      // border-bottom: 2px solid var(--color-text);
      background-color: var(--color-text);
      color: var(--color-bg);
    }

    // }
  }
}

#Footer {
  padding: 5vh 0;
  position: relative;
  background-color: var(--color-bg);

  // background-color: var(--color-text);
  // color: var(--color-bg);

  nav {
    width: 100%;

    .space {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
    }

    #Mailinglist {
      width: 30vw;
      label {
        position: absolute;
        top: -1.2em;
      }
    }

    @media only screen and (max-width: 600px) {
      #Mailinglist {
        // display: none;
        flex: 1 0 100%;
        max-width: 85vw;
        margin-top: calc(5vmin + 1em);
      }
    }

    a {
      text-align: center;
      text-decoration: none;
      font-size: calc(clamp(0.75rem, 1.5rem, 3vw));
      // font-style: italic;
      // font-weight: bold;

      display: flex;
      gap: 0.33em;
      // flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        height: 0.85em;
      }
    }
  }
}

#Gallery {
  flex: 1;
  // display: grid;
  // grid-template-columns: repeat(var(--columns), 1fr);
  // gap: 5px;

  width: 100%;
  height: 100%;
  overflow-x: hidden;

  // -ms-overflow-style: none; /* IE 11 */
  // scrollbar-width: none; /* Firefox 64 */
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  .section-header {
    opacity: 0;
    transition: opacity 0.5s 0.25s, top 0.5s;
    position: absolute;
    font-size: 2.5rem;
    margin: 0;
    margin-left: 10px;
    font-weight: normal;
    transform: translateY(-100%);
    pointer-events: none;

    white-space: nowrap;

    font-family: Ampersand, Larken;
    line-height: 1.75em;

    @media only screen and (max-width: 400px) {
      font-size: 10vw;
    }

    &.active {
      opacity: 1;
    }
  }

  .images-container {
    position: relative;
    overflow: hidden;
    transition: height 0.5s linear;
  }

  &.entryActive {
    pointer-events: none;
  }
}

#Image {
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;

  .image-body {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    display: block;
  }
  .divider {
    width: 25px;
    border: 1px solid var(--color-text-light);
  }

  .overlay {
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    color: var(--color-text-light);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    transition: opacity 0.5s;
    opacity: 0;
    &:hover,
    &:focus-within {
      opacity: 1;
    }

    &.touchDevice {
      opacity: 0 !important;
    }

    .overlay-link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .title {
      padding: 20px;
      font-style: italic;
      font-size: 2em;
      text-align: center;
    }

    .client {
      text-align: center;
      padding: 15px;
    }

    .tags {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      // display: flex;

      .left-arrow,
      .right-arrow {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }

      .scroll-horizontal {
        & > div {
          min-width: 100%;
          gap: 0px;
          padding: 0;
        }
      }

      a {
        opacity: 0.5;
        &:hover,
        &:focus {
          opacity: 1;
          outline: none;
        }
        white-space: nowrap;
        padding: 5px;

        &.active {
          background-color: transparent;
          font-style: italic;
        }
      }
    }
  }

  svg {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 0;
    transition: opacity 0.5s;

    .eye {
      animation: rotateEye 2s infinite linear;
    }
  }
  .image-body {
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
    transform: translate(-45%, -45%) scale(0.1);
  }

  pointer-events: none;
  visibility: visible;
  &:not(.active) {
    transition: visibility 0.5s;
    visibility: hidden;
  }

  &.active {
    transition: left 0.5s, top 0.5s;
    pointer-events: all;

    &:not(.loaded) {
      svg {
        opacity: 1;
      }
    }

    &.loaded {
      .image-body {
        opacity: 1;
        transform: translate(-0%, -0%) scale(1);
      }
    }
  }
}

// .transition-exit.transition-exit-active #Image img {
//   opacity: 0;
//   transform: translate(-50%, -50%) scale(0);
//   transition-delay: 0 !important;
// }

@keyframes rotateEye {
  0% {
    transform: translate(50%, 40%) rotate(0deg) translate(-50%, -40%);
  }
  50% {
    transform: translate(50%, 40%) rotate(180deg) translate(-50%, -40%);
  }
  100% {
    transform: translate(50%, 40%) rotate(360deg) translate(-50%, -40%);
  }
}

#Entries {
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;

  pointer-events: none;
  // backdrop-filter: blur(4px) grayscale(100%) opacity(0);
  background-color: rgba(0, 0, 0, 0);
  transition: opacity 0.5s, background-color 1s;

  &:not(.active) {
    transition-delay: 0.25s;
  }

  &.active {
    pointer-events: all;
    background-color: rgba(255, 255, 255, 0.98);
  }
}

#Entry {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.active {
    z-index: 3;
  }

  .prev-next-container {
    z-index: 1;
    position: fixed;

    top: 0;
    right: 0;
    // margin: 20px;
    margin-right: calc(var(--scrollbar-width));
    display: flex;

    a {
      padding: 18px;
      height: 15px;
      img {
        height: 100%;
      }
      transition: transform 0.1s;
      &:active {
        transform: scale(0.75);
      }
    }
  }

  .close {
    z-index: 1;
    position: fixed;

    top: 0;
    left: 0;

    a {
      padding: 12px;
      height: 35px;

      img {
        height: 100%;
      }

      display: block;
      transition: transform 0.1s;
      &:active {
        transform: scale(0.75);
      }
    }
  }

  .entry-scroll {
    position: relative;

    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    min-width: 100vmin;

    .entry-body {
      flex: 1;
      padding: 30px 0;
      // @media (orientation: portrait) {
      //   padding: 30px 0;
      // }
      // padding-bottom: 100px;

      margin: 0 auto;
      // max-width: 80vw;
      width: 100vmin;

      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;

      .entry-heading {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      img {
        max-width: 100vw;
        max-height: 100vh;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);
      }
      .quote-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .quote {
          white-space: pre-wrap;
          font-style: italic;
          width: 100%;
          font-size: 1.5rem;
          // margin-bottom: 1rem;
        }
        .author {
          font-style: italic;
          font-size: 1.2rem;
          line-height: 1.25em;
        }
      }
      .client {
        // margin-bottom: 1rem;
      }
      .links {
        margin: 0;
        padding-left: 1.75em;

        li {
          list-style: none;
          position: relative;
          line-height: 1.3em;

          &::before {
            top: 0.1em;
            left: -1.75em;
            position: absolute;
            display: block;
            content: " ";
            width: 1.5em;
            height: 1em;
            background-image: url("icons/logo-eye-simple.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        a {
          text-decoration: underline;
          font-style: italic;
        }
      }

      h1 {
        hyphens: manual;
        width: 100%;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        font-size: 4rem;
        // font-size: calc(max(2rem, 8vmin));
        margin-top: 50px;

        margin-bottom: 0;

        line-height: 1em;
        font-weight: normal;

        @media only screen and (max-width: 350px) {
          font-size: 20vw;
        }
      }
      @media (orientation: portrait) {
        .entry-heading,
        .tags-container {
          box-sizing: border-box;
          max-width: 100vw;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .tags-container {
        align-self: stretch;
        display: flex;
        gap: 15px;
        flex-flow: wrap;
        row-gap: 25px;

        a {
          line-height: 1.1em;
          white-space: nowrap;

          &:hover {
            opacity: 0.8;
            // text-decoration: underline;
          }
        }
      }
    }
  }

  visibility: hidden;
  transition: visibility 0.75s;

  .entry-scroll {
    transform: translateX(100%);
    transition: transform 0.75s;

    &:focus {
      outline: none;
    }
  }

  &:not(.animDone),
  &:not(.active) {
    width: calc(100% - var(--scrollbar-width));
    .entry-scroll {
      // overflow: hidden;
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox 64 */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.left {
    .entry-scroll {
      transform: translateX(-100%);
    }
  }
  &.right {
    .entry-scroll {
      transform: translateX(100%);
    }
  }

  .close,
  .prev-next-container {
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: opacity 0.5s;
  }

  &.active {
    visibility: visible;

    .close,
    .prev-next-container {
      opacity: 1;
      transition-delay: 0.33s;
    }
    .entry-scroll {
      transition-delay: 0.25s;
      transform: translateX(0);
      opacity: 1;
    }
  }
}

#About {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  padding-top: 30px;

  .about-body {
    align-self: center;
    flex: 1 0 100%;

    max-height: auto;
    height: auto;

    width: 75vmin;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 60px;

    h1 {
      font-weight: normal;
      font-size: 3rem;
      margin: 1rem 0 2rem 0;
    }

    p {
      white-space: pre-wrap;
      font-style: italic;
    }

    .charm-chain {
      margin-top: 30px;
      display: flex;
      gap: 3px;
      margin-left: -2px;
      flex-wrap: wrap;
      max-width: 100%;
      align-items: center;

      img {
        display: block;
        height: 35px;
        padding: 2px;

        border-radius: 50%;
        &.wide {
          border-radius: 5px;
        }

        &:hover {
          border: 1px solid black;
          margin: -1px;
        }
      }
    }
    .subtitle {
      line-height: 1.3em;
      margin-top: 1rem;
      a {
        text-decoration: underline;
      }
      // text-align: right;
      // width: 100%;
    }
  }
}

#Mailinglist {
  position: relative;

  label {
    font-size: 0.8rem;
    // font-weight: bold;
    font-style: italic;
    margin-left: 3px;
    white-space: nowrap;
  }
  .input-container {
    width: 100%;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    input {
      // padding: 0 5px;
      flex: 1;
      font-family: inherit;
      font-size: 1rem;
      min-width: 0;

      &::placeholder {
        font-family: inherit;
      }
    }
    button {
      font-family: inherit;
    }
  }
}

#Playlists {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  padding-top: 30px;

  .playlists-body {
    flex: 1;
    margin-bottom: 60px;

    align-self: center;
    width: 75vmin;
    display: flex;
    flex-direction: column;
    gap: 30px;

    // iframe {
    //   align-self: center;
    // }

    .list {
      gap: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      // height: max-content;
    }

    h1 {
      font-weight: normal;
      font-size: 3rem;
      margin: 1rem 0 2rem 0;
    }

    h2 {
      font-weight: normal;
      font-size: 2rem;
    }

    .content,
    .content p {
      white-space: pre-wrap;
      font-style: italic;
    }
  }
}

#SpotifyWidget {
  flex: 1 0 40%;
  min-width: 250px;
  min-height: 33vh;

  .square {
    position: relative;
    margin-bottom: 10px;
    img {
      width: 100%;
      display: block;
      // height: 100%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100.1%; // .1% ff hack

      transition: clip-path 1s;
      clip-path: circle(20px at 40px 40px);
    }
  }

  &:hover,
  &:focus-within {
    iframe {
      clip-path: circle(140% at 40px 40px);
    }
  }

  .title {
    // font-weight: bold;
    // font-style: italic;
  }
  .subtitle {
    font-style: italic;
  }
}

#About,
#Playlists {
  &.transition-enter {
    opacity: 0;
    position: absolute;
  }
  &.transition-enter.transition-enter-active {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &.transition-enter {
    transition-delay: 0.25s !important;
    transform: translateY(10vh);
  }
  &.transition-enter.transition-enter-active {
    transform: translateY(0%);
    transition: opacity 0.5s, transform 0.5s;
  }
}

.transition-exit {
  opacity: 1;
  position: absolute;
}
.transition-exit.transition-exit-active {
  opacity: 0;
  transition: opacity 0.5s;
}
